import React from "react"
import Layout from "../components/layout"
import WI from "../components/workimage"
import ReviewSlider from "../components/reviewSlider"
import SEO from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import ServiceMed from "../svg/medical.svg"
import ServiceWash from "../svg/wash.svg"
import ServiceTeeth from "../svg/teeth.svg"
import ServiceCut from "../svg/scissor-and-comb.svg"
import Dropdown from "../components/dropdown"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      servicesImage: file(relativePath: { eq: "services.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      heroImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      aboutImage: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviewImage: file(relativePath: { eq: "review.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert1: file(relativePath: { eq: "certs/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert2: file(relativePath: { eq: "certs/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert3: file(relativePath: { eq: "certs/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert4: file(relativePath: { eq: "certs/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert5: file(relativePath: { eq: "certs/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert6: file(relativePath: { eq: "certs/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert7: file(relativePath: { eq: "certs/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert8: file(relativePath: { eq: "certs/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert9: file(relativePath: { eq: "certs/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cert10: file(relativePath: { eq: "certs/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work1a: file(relativePath: { eq: "works/1a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work2a: file(relativePath: { eq: "works/2a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work3a: file(relativePath: { eq: "works/3a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work1: file(relativePath: { eq: "works/1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work2: file(relativePath: { eq: "works/2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work3: file(relativePath: { eq: "works/3.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work4a: file(relativePath: { eq: "works/4a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work4: file(relativePath: { eq: "works/4.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work6a: file(relativePath: { eq: "works/6a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work6: file(relativePath: { eq: "works/6.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work5a: file(relativePath: { eq: "works/5a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work5: file(relativePath: { eq: "works/5.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work7a: file(relativePath: { eq: "works/7a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work7: file(relativePath: { eq: "works/7.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work8a: file(relativePath: { eq: "works/8a.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work8: file(relativePath: { eq: "works/8.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client1: file(relativePath: { eq: "clients/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Профессиональный груминг в Витебске" />
      <section className="hero">
        <div className="hero-image">
          <Img fluid={data.heroImage.childImageSharp.fluid} />
        </div>
        <div className="wrap">
          <div>
            <h1
              data-sal="slide-left"
              data-sal-delay="10"
              data-sal-easing="ease-out"
              data-sal-duration="500ms">
              Ваша <br />
              <span>заявка</span>
              <br /> принята
            </h1>
            <div
              data-sal="slide-left"
              data-sal-delay="10"
              data-sal-easing="ease-out"
              data-sal-duration="500ms">
              <h2>
                Мы скоро свяжемся с<br />
                вами для уточнения деталей.
              </h2>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
